header {
  padding-top: 7rem;
  display: flex;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ======================= Wave Hand ======================= */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: flex;
  font-size: 11rem;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* ======================= Name ======================= */

.name {
  --c: linear-gradient(rgb(255, 255, 255) 0 0); /* update the color here */

  background: var(--c), var(--c);
  background-size: 10em 0.1em;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  transition: 0.3s linear, background-size 0.3s 0.2s linear;
}

.name:hover {
  background-size: 55% 0.1em;
}

/* ======================= CTA ======================= */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ======================= Header Socials ======================= */

.header__socials {
  font-size: 2rem;
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid;
}

.header__socials::after {
  background: var(--color-primary);
}

/* ======================= Me ======================= */

.me {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.me img {
  border-radius: 12rem 12rem 0 0;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 50%,
    transparent 100%
  );
}

/* ======================= Scroll Down ======================= */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1.2rem;
}

/* ======================= Media Queries (Medium Devices) ======================= */

@media screen and (max-width: 1024px) {
  header {
    height: 78vh;
  }
}

/* ======================= Media Queries (Small Devices) ======================= */

@media screen and (max-width: 600px) {
  header {
    height: 88vh;
  }

  .scroll__down {
    display: none;
  }
}

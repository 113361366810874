.portfolio__container {
  width: 30%;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item-image {
  border-radius: 1.5rem;
  width: 700px;
  margin: 0 auto 1rem;
  overflow: hidden;
  border: 0.4rem solid var(--color-primary-variant);
}

.portfolio__item-video {
  border-radius: 1.5rem;
  width: 840px;
  height: 475px;
  margin: 0 auto 0;
  overflow: hidden;
  border: 0.4rem solid var(--color-primary-variant);
  position: "relative";
}

.portfolio__item h3 {
  padding: 2rem;
  user-select: none;
  display: block;
  width: 80%;
  margin: 0 auto 0;
}

.portfolio__item p {
  padding: 0 2rem 0 2rem;
  display: block;
  width: 80%;
  margin: 0 auto 0;
  text-align: justify;
}

.portfolio__item-cta {
  padding: 0 2rem 0 2rem;
  user-select: none;
  display: block;
  width: 80%;
  margin: 1rem auto 0;
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary);
  opacity: 1;
}

.swiper-button-next {
  --swiper-navigation-color: var(--color-primary);
}

.swiper-button-prev {
  --swiper-navigation-color: var(--color-primary);
}

/* ======================= Media Queries (Medium Devices) ======================= */

@media screen and (max-width: 1024px) {
  .portfolio__item-image {
    width: 600px;
  }
}

/* ======================= Media Queries (Small Devices) ======================= */

@media screen and (max-width: 600px) {
  .portfolio__item-image {
    width: 90%;
  }

  .portfolio__item-video {
    width: 360px;
    height: 280px;
    position: relative;
  }

  .portfolio__item h3 {
    padding: 0.7rem;
    user-select: none;
    display: block;
    width: 80%;
    margin: 0 auto 0;
    font-size: medium;
  }

  .portfolio__item p {
    padding: 0 0 0 0;
    width: 80%;
    text-align: left;
    font-size: small;
  }
}

.about__container {
  display: grid;
  grid-template-columns: 50% 35%;
  gap: 20%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(10deg, transparent, var(--color-primary));
  transform: rotate(0deg);
  display: grid;
  place-items: center;
}

.about__me-image {
  width: 90%;
  border-radius: 2rem;
  overflow: hidden;

  border: 2px solid transparent;

  text-align: center;
  transition: var(--transition);
  --s: 100%;
  --b: 2px; /* the border thickness*/
  --g: 1px; /* the gap */
  --c: var(--color-primary); /* the color */
  outline: calc(var(--s) / 2) solid rgba(71, 103, 122, 0.3);
  outline-offset: calc(var(--s) / -2);
  cursor: pointer;
  transition: 0.3s;
}

.about__me-image:hover {
  transform: rotate(0deg);
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(1.2);
  outline: var(--b) solid var(--c);
  outline-offset: var(--g);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  width: 180px;
  height: 180px;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
  --s: 180px;
  --b: 2px; /* the border thickness*/
  --g: 1px; /* the gap */
  --c: var(--color-primary); /* the color */
  outline: calc(var(--s) / 2) solid rgba(71, 103, 122, 0.3);
  outline-offset: calc(var(--s) / -2);
  cursor: pointer;
  transition: 0.3s;
}

.about__card:hover {
  outline: var(--b) solid var(--c);
  outline-offset: var(--g);
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 0.1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.86rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/* ======================= Media Queries (Medium Devices) ======================= */

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ======================= Media Queries (Small Devices) ======================= */

@media screen and (max-width: 600px) {
  .about__me {
    width: 65%;
    margin: 1 auto 3rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}
